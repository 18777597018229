<script setup>
import { computed, onMounted, ref } from 'vue'
import { RouterLink } from 'vue-router'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import Banner from '@/components/common/Banner.vue'
import { useUserStore } from '@/stores/user'
import TheSidebar from '@/components/layout/TheSidebar.vue'
import SidebarMobile from '@/components/layout/SidebarMobile.vue'
import WhatsApp from '@/components/icons/Whatsapp.vue'
import RequestPhoneNumberModal from '@/components/layout/RequestPhoneNumberModal.vue'

const store = useUserStore()

const showRequestPhoneNumberModal = ref(false)

const usage = computed(() => store.usage)
const cloakupSubscription = computed(() => store.cloakupSubscription)

const percentage = computed(() => {
  const result = getPercentage(usage.value?.usage ?? 0)
  return result > 100 ? 100 : result
})

const isOverLimit = computed(() => {
  return percentage.value >= 100
})

const height = computed(() => {
  return isOverLimit.value ? 47 : 1
})

function getPercentage(earnings) {
  return (earnings / usage?.value?.limit) * 100
}

onMounted(() => {
  if (!store.user?.phone) {
    showRequestPhoneNumberModal.value = true
  }
})
</script>

<template>
  <div>
    <RouterLink
      v-if="isOverLimit"
      :to="`/settings/subscriptions/${cloakupSubscription?.id}/upgrade`"
    >
      <Banner
        :title="$t('components.account_status.limit')"
        :description="$t('components.account_status.upgrade')"
        variant="destructive"
      />
    </RouterLink>

    <div
      class="grid w-full overflow-hidden  bg-background lg:grid-cols-[288px_1fr]"
      :style="{ height: `calc(100vh - ${height}px)` }"
    >
      <div class="hidden bg-background lg:block">
        <TheSidebar :style="{ height: `calc(100vh - ${height}px)` }" />
      </div>

      <div class="relative flex min-h-svh flex-1 flex-col overflow-y-auto bg-background">
        <div class="block w-full border-b bg-muted/35 lg:hidden">
          <SidebarMobile />
        </div>

        <div class="flex flex-1 flex-col gap-4 p-4 pt-0">
          <div
            v-if="cloakupSubscription?.id"
            class="fixed bottom-24 right-6 z-10"
          >
            <a
              href="https://wa.me/5551998629423"
              target="_blank"
            >
              <WhatsApp class=" size-16" />
            </a>
          </div>
          <div class="mx-auto w-full max-w-7xl p-6">
            <router-view v-slot="{ Component }">
              <Suspense timeout="0">
                <component
                  :is="Component"
                  :key="$route.params?.id"
                />
                <template #fallback>
                  <div class="flex items-center justify-center">
                    <BaseSpinner class="text-primary" />
                  </div>
                </template>
              </Suspense>
            </router-view>
          </div>
        </div>
      </div>
    </div>
    <RequestPhoneNumberModal v-model="showRequestPhoneNumberModal" />
  </div>
</template>
