import { ComputerDesktopIcon, DevicePhoneMobileIcon, DeviceTabletIcon, FireIcon, GlobeAltIcon, PhoneIcon } from '@heroicons/vue/24/solid'
import { defineAsyncComponent } from 'vue'

export function getOsIcon(os) {
  const icons = {
    'android': defineAsyncComponent(() => import('@/components/icons/AndroidIcon.vue')),
    'mac': defineAsyncComponent(() => import('@/components/icons/MacOs.vue')),
    'ios': defineAsyncComponent(() => import('@/components/icons/MacOs.vue')),
    'windows': defineAsyncComponent(() => import('@/components/icons/WindowsIcon.vue')),
    'gnu/linux': defineAsyncComponent(() => import('@/components/icons/LinuxIcon.vue')),
    'ubuntu': defineAsyncComponent(() => import('@/components/icons/UbuntuIcon.vue')),
  }

  return icons[os] ?? null
}

export function getBrowserIcon(browser) {
  const icons = {
    'chrome': defineAsyncComponent(() => import('@/components/icons/browsers/ChromeIcon.vue')),
    'chrome mobile': defineAsyncComponent(() => import('@/components/icons/browsers/ChromeIcon.vue')),
    'chrome webview': defineAsyncComponent(() => import('@/components/icons/browsers/ChromeIcon.vue')),
    'chrome mobile ios': defineAsyncComponent(() => import('@/components/icons/browsers/ChromeIcon.vue')),
    'mobile safari': defineAsyncComponent(() => import('@/components/icons/browsers/SafariIcon.vue')),
    'safari': defineAsyncComponent(() => import('@/components/icons/browsers/SafariIcon.vue')),
    'samsung browser': defineAsyncComponent(() => import('@/components/icons/browsers/SamsungBrowserIcon.vue')),
    'instagram app': defineAsyncComponent(() => import('@/components/icons/browsers/InstagramIcon.vue')),
    'facebook': defineAsyncComponent(() => import('@/components/icons/browsers/FacebookIcon.vue')),
    'microsoft edge': defineAsyncComponent(() => import('@/components/icons/browsers/MicrosoftEdgeIcon.vue')),
    'google search app': defineAsyncComponent(() => import('@/components/icons/browsers/GoogleSearchIcon.vue')),
    'firefox': defineAsyncComponent(() => import('@/components/icons/browsers/FirefoxIcon.vue')),
    'baidu spark': defineAsyncComponent(() => import('@/components/icons/browsers/BaiduIcon.vue')),
    'opera': defineAsyncComponent(() => import('@/components/icons/browsers/OperaIcon.vue')),
    'internet explorer': defineAsyncComponent(() => import('@/components/icons/browsers/InternetExplorer.vue')),
    'edge': defineAsyncComponent(() => import('@/components/icons/browsers/Edge.vue')),
  }

  return icons[browser] ?? GlobeAltIcon
}

export function getDeviceIcon(device) {
  const icons = {
    desktop: ComputerDesktopIcon,
    mobile: PhoneIcon,
    tablet: DeviceTabletIcon,
    smartphone: DevicePhoneMobileIcon,
  }

  return icons[device] ?? null
}
