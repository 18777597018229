<script setup>
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { toast } from 'vue-sonner'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { Field } from '@/components/ui/field'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import { useUserStore } from '@/stores/user'
import { userService } from '@/services/user'
import PhoneInput from '@/components/common/PhoneInput.vue'

const model = defineModel({
  required: true
})

const { t } = useI18n()
const store = useUserStore()

const isLoading = ref(false)
const errors = ref({})
const form = reactive({
  name: store.user.name,
  phone: store.user.phone,
})

function submit() {
  const payload = {
    name: form.name,
    phone: form.phone?.replace(/\D/g, ''),
  }

  updateAccount(payload)
}

async function updateAccount(user) {
  isLoading.value = true
  errors.value = {}
  try {
    await userService.updateProfile(user)

    toast.success(t('components.update_profile.toast.success.title'), {
      description: t('components.update_profile.toast.success.description'),
    })
    model.value = false
    form.phone = ''

    store.user.phone = user.phone
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data?.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <Dialog v-model:open="model">
    <DialogContent class="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>
          {{ $t('request_phone_number') }}
        </DialogTitle>
        <DialogDescription>
          {{ $t('request_phone_number_description') }}
        </DialogDescription>
      </DialogHeader>
      <form
        id="request-phone-number-form"
        @submit.prevent="submit"
      >
        <fieldset :disabled="isLoading">
          <Field>
            <Label for="phone">
              {{ $t("whatsapp") }}
            </Label>
            <PhoneInput
              id="phone"
              v-model="form.phone"
              type="tel"
              name="phone"
              autocomplete="tel"
              placeholder="(00) 00000-0000"
              :invalid="errors?.phone"
            />
            <ErrorMessage
              :message="errors?.phone"
            />
          </Field>
        </fieldset>
      </form>
      <DialogFooter>
        <Button
          type="submit"
          form="request-phone-number-form"
          :disabled="isLoading"
          :loading="isLoading"
        >
          {{ $t('common.save') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
