<template>
  <div
  :class="cn(bannerVariants({ variant }))"
  >
    <p class="text-sm/6 text-white">
        <strong class="font-semibold">
          {{ title }}
        </strong><svg viewBox="0 0 2 2" class="mx-2 inline size-0.5 fill-current" aria-hidden="true">
          <circle cx="1" cy="1" r="1" /></svg>
          {{ description }}
          <span aria-hidden="true">&rarr;</span>
    </p>
  </div>
</template>

<script setup>
import { cva } from 'class-variance-authority';
import { cn } from '@/lib/utils'

const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: false,
  },
  to: {
    type: String,
    required: true,
  },
  variant: {
    type: String,
    required: false,
    default: 'default',
  },
})

 const bannerVariants = cva(
  'flex items-center gap-x-6 bg-gray-900 px-6 py-2.5 sm:px-3.5 justify-center  w-full flex-1',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground shadow hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)
</script>