<script setup>
import { computed } from 'vue'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { formatCurrency } from '@/helpers/currency.js'
import { formatNumber } from '@/helpers/format.js'
import Description from '@/components/ui/description/Description.vue'

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  }
})
const emits = defineEmits(['update:modelValue', 'change'])
const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:modelValue', value)
    emits('change', value)
  }
})
</script>

<template>
  <RadioGroup
    v-model="model"
    class="grid max-w-md gap-2"
  >
    <div
      v-for="(plan, planIdx) in options"
      :key="plan.name"
      class="relative flex flex-col gap-4 rounded-lg border border-input p-4 shadow-sm shadow-black/[.04] has-[[data-state=checked]]:border-ring"
    >
      <div>
        <RadioGroupItem
          :id="plan.id"
          :value="plan"
          class="order-1 after:absolute after:inset-0"
        />
      </div>
      <div>
        <Label for="option-one">{{ plan.name }}</Label>
        <Description>
          {{ formatCurrency(plan.price, plan.currency) }} / {{ $t(`common.plan_cycle.${plan.cycle}`) }}
        </Description>
        <Description>
          {{ formatNumber(plan.limit) }} {{ $t('common.requests') }}
        </Description>
        <Description>
          {{ formatNumber(plan.domains) }} {{ $t('common.domains', {
            count: plan.domains,
          }) }}
        </Description>
      </div>
    </div>
  </RadioGroup>
</template>
