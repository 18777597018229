<script setup>
import { toast } from 'vue-sonner'
import { ref } from 'vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import { useCampaignStore } from '@/stores/campaign'
import { generateQueryString } from '@/helpers/query-string'

const props = defineProps({
  campaign: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['convert'])
const { t } = useI18n()

const campaignStore = useCampaignStore()
const isOpen = defineModel({
  required: true,
  type: Boolean,
})

const isLoading = ref(false)

function convertNetworks(filtersActive) {
  const data = {
    tiktok: false,
    google: false,
    facebook: false,
    kwai: false,
  }

  if (filtersActive.tiktok) {
    data.tiktok = true
    return data
  }

  if (filtersActive.google) {
    data.google = true
    return data
  }

  if (filtersActive.facebook) {
    data.facebook = true
    return data
  }

  if (filtersActive.kwai) {
    data.kwai = true
    return data
  }

  return data
}

async function convertToBasic() {
  const includesBR = props.campaign.filters.geolocation.countries.includes('BR')
  const shouldIncludeProxy = !includesBR
  const networks = convertNetworks(props.campaign.filters)

  const converted = {
    name: props.campaign.name,
    domain: props.campaign.domain,
    alias: props.campaign.alias,
    mode: 'basic',
    pages: {
      white: {
        type: props.campaign.pages.white.type,
        content: props.campaign.pages.white.content
      },
      offers: props.campaign.pages.offers.map(offer => ({
        content: offer.content,
        share: offer.share,
        type: offer.type
      }))
    },
    filters: {
      tiktok: networks.tiktok,
      google: networks.google,
      facebook: networks.facebook,
      kwai: networks.kwai,
      cloakup_ai: true,
      adspy: true,
      bots: true,
      proxy: shouldIncludeProxy,
      deny_always: false,
      geolocation: {
        allow: true,
        countries: props.campaign.filters.geolocation.countries
      },
      device: {
        allow: true,
        devices: props.campaign.filters.device.devices
      },
      query: {
        allow: true,
        params: Object.keys(props.campaign.filters.query?.params)?.length ? props.campaign.filters.query.params : generateQueryString(),
        condition: 'some',
        remove_params: false,
        rules: props.campaign.filters.query.rules || {}
      },
      browser_language: {
        allow: true,
        languages: props.campaign.filters.browser_language.languages
      },
      referer: {
        block_null: false,
        allow: true,
        domains: []
      },
      domain: {
        allow: true,
        domains: []
      },
      isp: {
        allow: true,
        isps: []
      },
      os: {
        allow: true,
        os: []
      },
      user_agent: {
        allow: true,
        user_agents: []
      },
      blacklist: [],
      whitelist: []
    }
  }

  try {
    isLoading.value = true
    await campaignStore.update(props.campaign.id, converted)

    isOpen.value = false
    emit('convert')

    toast.success(t('components.campaign_convert_to_basic_form.toast.success.title'), {
      description: t('components.campaign_convert_to_basic_form.toast.success.description'),
    })
  } catch (error) {
    toast.error(error.response?.data?.message, {
      description: error.response?.data?.action || error.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {{ $t('components.campaign_convert_to_basic_form.title') }}
        </DialogTitle>
        <DialogDescription>
          {{ $t('components.campaign_convert_to_basic_form.description') }}
        </DialogDescription>
      </DialogHeader>

      <Alert variant="destructive">
        <ExclamationTriangleIcon class="size-4" />
        <AlertTitle>
          {{ $t('components.campaign_convert_to_basic_form.alert.title') }}
        </AlertTitle>
        <AlertDescription>
          {{ $t('components.campaign_convert_to_basic_form.alert.message') }}
        </AlertDescription>
      </Alert>

      <DialogFooter>
        <DialogClose>
          <Button variant="secondary">
            {{ $t('common.cancel') }}
          </Button>
        </DialogClose>
        <Button
          :disabled="isLoading"
          :loading="isLoading"
          @click="convertToBasic"
        >
          {{ $t('components.campaign_convert_to_basic_form.button') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
