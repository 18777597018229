<script setup>
import { computed } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { useUserStore } from '@/stores/user'
import Card from '@/components/ui/card/Card.vue'
import CardContent from '@/components/ui/card/CardContent.vue'
import CardHeader from '@/components/ui/card/CardHeader.vue'
import { CardTitle } from '@/components/ui/card'
import { Progress } from '@/components/ui/progress'
import { Skeleton } from '@/components/ui/skeleton'

const store = useUserStore()

const usage = computed(() => store.usage)
const cloakupSubscription = computed(() => store.cloakupSubscription)

const percentage = computed(() => {
  const result = getPercentage(usage.value?.usage ?? 0)
  return result > 100 ? 100 : result
})

function getPercentage(earnings) {
  return (earnings / usage?.value?.limit) * 100
}



function formatNumber(number) {
  return new Intl.NumberFormat('pt-BR').format(number)
}
</script>

<template>
  <div
    v-if="store.user?.cloakup_active"
    class="transition duration-150 ease-in-out hover:scale-95"
  >
    <template v-if="store.isFetchingUsage ">
      <div class="flex items-center justify-center ">
        <Skeleton
          class="h-24 w-full"
        />
      </div>
    </template>
    <template v-else>
        <Card>
          <CardHeader class="p-3">
            <CardTitle>
              {{ store.cloakupSubscription?.plan?.name }}
            </CardTitle>
          </CardHeader>

          <CardContent class="p-3">
            <div>
              <div class="flex items-center justify-between">
                <span class="text-sm text-muted-foreground">
                  {{ $t('common.requests') }}
                </span>
                <span class="text-sm text-muted-foreground">{{ formatNumber(usage?.usage) }} /
                  {{ formatNumber(usage?.limit) }}</span>
              </div>
            </div>
            <div class="mt-2">
              <Progress :model-value="percentage" />
            </div>
          </CardContent>
        </Card>
    </template>
  </div>
</template>
