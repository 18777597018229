<script setup>
import { loadStripe } from '@stripe/stripe-js'
import { h, onMounted, ref } from 'vue'
import { cardService } from '@/services/card'
import Button from '@/components/ui/button/Button.vue'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import RadioGroupCards from '@/components/RadioGroupCards.vue'
import { getBrandIcon } from '@/helpers/get-brand-svg'

const props = defineProps({
  clientSecret: {
    type: String,
    required: true
  }
})
const emit = defineEmits(['paid'])
const isOpen = defineModel({
  required: true
})

const cards = ref([])
const isLoading = ref(false)
const selectedPaymentMethod = ref({
  token: null
})
let stripe = null

async function onSubmit() {
  if (!stripe) {
    return
  }

  isLoading.value = true

  try {
    await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: selectedPaymentMethod.value?.token
    })

    isOpen.value = false
    emit('paid')
  } catch (err) {

  } finally {
    isLoading.value = false
  }
}

async function getAllCards() {
  isLoading.value = true
  try {
    const { data } = await cardService.findAll({
      page: 1,
      limit: 50,
      gateway: 'stripe'
    })
    cards.value = [...cards.value, ...data.data]

    const defaultCard = cards.value.find(card => card.default)
    if (defaultCard) {
      selectedPaymentMethod.value = defaultCard
    } else {
      selectedPaymentMethod.value = cards.value[0]
    }
  } catch (error) {

  } finally {
    isLoading.value = false
  }
}

onMounted(async () => {
  if (isOpen.value) {
    getAllCards()
    stripe = await loadStripe(import.meta.env.VITE_STRIPE_PK)
  }
})
</script>

<template>
  <div>
    <Dialog v-model:open="isOpen">
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Pagar fatura
          </DialogTitle>
          <DialogDescription>
            Selecione um cartão de crédito para pagar a fatura.
          </DialogDescription>
        </DialogHeader>

        <form
          id="pay-invoice-form"
          @submit.prevent="onSubmit"
        >
          <fieldset :disabled="isLoading">
            <div>
              <RadioGroupCards
                v-model="selectedPaymentMethod"
                class="grid w-full grid-cols-1 gap-2 sm:grid-cols-2"
                :options="cards.map((card) => {
                  return {
                    value: card.token,
                    label: `**** **** **** ${card.last_four_digits}`,
                    icon: getBrandIcon(card.brand),
                  }
                })"
              />
            </div>
          </fieldset>
        </form>

        <DialogFooter>
          <DialogClose>
            <Button variant="secondary">
              {{ $t('common.cancel') }}
            </Button>
          </DialogClose>
          <Button
            :disabled="isLoading"
            :loading="isLoading"
            form="pay-invoice-form"
          >
            Pagar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>
