<script setup>
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import Label from '@/components/ui/label/Label.vue'

defineProps({
  options: {
    type: Array,
    default: () => [],
  }
})

const model = defineModel({
  required: true,
  default: () => [],
})
</script>

<template>
  <RadioGroup
    v-model="model"
  >
    <div
      v-for="option in options"
      :key="option.value"
      class="relative flex cursor-pointer flex-col gap-4 overflow-hidden rounded-lg  p-4 shadow-sm ring-1 ring-border hover:bg-muted/50 has-[[data-state=checked]]:ring-2 has-[[data-state=checked]]:ring-ring"
    >
      <div class="flex justify-between gap-2">
        <RadioGroupItem
          :id="option.value"
          :value="option.value"
          class="peer order-1 size-4  after:absolute after:inset-0"
        />
        <component
          :is="option.icon"
          class="size-6 rounded-md text-foreground"
        />
      </div>
      <Label
        :for="option.value"
      >
        {{ option.label }}
      </Label>
    </div>
  </RadioGroup>
</template>
